<script>
import api from '@/services/api';
import vSelect from 'vue-select';
import { BCol, BFormCheckbox, BPagination, BRow } from 'bootstrap-vue';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear.vue';
import CleanIcon from '@/assets/images/icons/CleanIcon.vue';
import { MERCHANT_ID } from '@/constants/names';
import flatPickr from 'vue-flatpickr-component';

export default {
    name: 'ViewKubeControl',
    components: {
        BFormCheckbox,
        CleanIcon,
        ProgressLinear,
        BCol,
        BRow,
        BPagination,
        vSelect,
        flatPickr
    },
    data() {
        return {
            queueList: [],
            serverList: [],
            setIntervalList: [5000, 10000, 30000],
            setIntervalValue: 5000,
            doubleRequesting: null,
            pagination: {
                current: 1,
                totalItem: '',
                perPage: 12
            },
            requestPending: false,
            group_id: null,
            ussdInterval: null,
            ussdStatus: true,
            ussdBlockStartDate: null,
            ussdBlockEndDate: null,
            body: {
                start: null,
                end: null
            },
            userLoaded: null
        };
    },

    watch: {
        '$route.query': {
            async handler(query) {
                await this.ensureUserLoaded();
                if (this.$store.getters['me/hasPageAccess']) {
                    this.pagination.current = query.page || 1;
                    await this.fetchServerList();
                }
            },
            deep: true,
            immediate: true
        },

        'pagination.current': {
            handler(page) {
                let newPage = page;
                if (this.pagination.current > this.pagination.totalItem) {
                    newPage = 1;
                }
                this.replaceRouter({
                    ...this.query,
                    page: newPage
                });
            }
        }
    },

    beforeRouteLeave(to, from, next) {
        clearInterval(this.doubleRequesting);
        clearInterval(this.ussdInterval);
        this.doubleRequesting = null;
        this.ussdInterval = null;

        next();
    },

    async created() {
        await this.ensureUserLoaded();
        if (this.$store.getters['me/hasPageAccess']) {
            this.requestPending = true;
            await this.fetchQueue();
            await this.checkUSSDStatus();
            // await this.fetchServerList();
            await this.doubleRequest();
            this.requestPending = false;
        }
    },

    async mounted() {
        this.ussdInterval = setInterval(async () => {
            await this.checkUSSDStatus();
        }, 60000);
    },

    computed: {
        query() {
            return Object.assign({}, this.$route.query);
        },

        hasItems() {
            return this.serverList && this.serverList.length > 0;
        },

        showPagination() {
            return this.hasItems && !this.requestPending;
        }
    },

    methods: {
        async ensureUserLoaded() {
            if (!this.userLoaded) {
                this.userLoaded = this.$store.dispatch('me/getUserMe');
            }
            await this.userLoaded;
        },

        async fetchQueue() {
            try {
                const { data } = await api.kubeServers.fetchQueue();
                this.queueList = data.data.list;
                let tasks = null;
                this.queueList.map((item) => {
                    tasks += item.tasks;
                });
                this.queueList.unshift({
                    name: 'all',
                    id: null,
                    tasks: tasks,
                    load_half: 100000000,
                    load_limit: 100000000
                });
            } catch (e) {
                console.error(e);
            }
        },

        async fetchServerList() {
            const body = {
                page: this.pagination.current,
                limit: this.pagination.perPage,
                queue_trick_id: this.group_id || null
            };
            try {
                const { data } = await api.kubeServers.fetchServerList(body);
                this.serverList = data.data.list;
                this.pagination = data.data.pagination;
            } catch (e) {
                console.error(e);
            }
        },

        async cleanQueue(server) {
            try {
                await api.kubeServers.cleanQueue(server.id);
                this.$toast.success(this.$t('success.updated.cache_cleaned'));
            } catch (e) {
                console.error(e);
            } finally {
                this.$bvModal.hide(`clean-server-${server.id}`);
            }
        },

        fetchServerListByClick(groupId) {
            this.group_id = groupId;
            this.pagination.current = 1;
            this.fetchServerList();
        },

        async refreshButton() {
            await this.fetchQueue();
            await this.fetchServerList();
        },

        async doubleRequest() {
            this.doubleRequesting = setInterval(async () => {
                await this.fetchQueue();
                await this.fetchServerList();
            }, this.setIntervalValue);
        },

        async clearIntervalAndSetNew() {
            await clearInterval(this.doubleRequesting);
            await this.doubleRequest();
        },

        async triggerServer(server) {
            const serverId = server.id;
            try {
                if (server.status.isActive) {
                    await api.kubeServers.stopServer(serverId);
                    this.$bvModal.hide(`server-${serverId}`);
                    await this.fetchServerList();
                    await this.fetchQueue();
                } else {
                    await api.kubeServers.startServer(serverId);
                    this.$bvModal.hide(`server-${serverId}`);
                    await this.fetchServerList();
                    await this.fetchQueue();
                }
            } catch (e) {
                console.error(e);
            }
        },

        matchColorsForTasks(tasks, half, limit) {
            if (tasks === 0) {
                return '';
            } else if (tasks <= half) {
                return 'text-success';
            } else if (tasks > half && tasks <= limit) {
                return 'text-warning';
            } else {
                return 'text-danger';
            }
        },

        changePerPage(perPage) {
            this.pagination = {
                perPage,
                page: 1
            };
            this.fetchServerList();
        },

        replaceRouter(query) {
            this.$router.replace({ query }).catch(() => {});
        },

        async checkUSSDStatus() {
            const body = {
                merchant_id: parseInt(MERCHANT_ID)
            };
            try {
                const { data } = await api.ussdApi.checkUSSDStatus(body);
                this.ussdStatus = data.data.status;
                this.ussdBlockStartDate = data.data.start;
                this.ussdBlockEndDate = data.data.end;
            } catch (e) {
                console.error(e);
            }
        },

        async triggerUSSD() {
            const body = {
                merchant_id: parseInt(MERCHANT_ID),
                start: this.body.start,
                end: this.body.end
            };
            if (this.ussdStatus) {
                await api.ussdApi.deleteBlock({ merchant_id: parseInt(MERCHANT_ID) });
            } else {
                await api.ussdApi.setBlock(body);
            }
            await this.closeModals();
            await this.checkUSSDStatus();
        },

        closeModals() {
            this.$refs.firstVerificationModal.hide();
            this.$refs.secondVerificationModal.hide();
            this.body.start = null;
            this.body.end = null;
        },

        async openModal() {
            await this.checkUSSDStatus();
            if (this.ussdStatus === false) {
                this.$refs.firstVerificationModal.show();
            } else {
                this.$refs.secondVerificationModal.show();
            }
        }
    }
};
</script>

<template>
    <div class="control">
        <div v-if="$store.getters['me/hasPageAccess']">
            <div class="row">
                <div
                    class="col-12 d-flex flex-wrap align-items-center justify-content-between mb-1"
                >
                    <div class="col-12 col-md-4">
                        <h2>Servers Control</h2>
                    </div>
                    <div
                        class="col-12 col-md-8 d-flex flex-column justify-content-between flex-sm-row"
                    >
                        <!--      USSD ON/OFF      -->
                        <div class="d-flex align-items-center switch__button mb-1">
                            <!-- button  -->
                            <!--                <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary">-->
                            <!--                    Open First Modal-->
                            <!--                </b-button>-->
                            <span class="mr-1 font-weight-bold font-medium-3">USSD :</span>
                            <!--                <div v-b-modal.modal-multi-1 @click="checkUSSDStatus">-->
                            <div @click="openModal">
                                <b-form-checkbox
                                    :checked="!ussdStatus"
                                    class="custom-control-success reversed-switch"
                                    name="check-button"
                                    switch
                                    disabled
                                >
                                    <span class="switch-icon-left">
                                        <feather-icon icon="CheckIcon" />
                                    </span>
                                    <span class="switch-icon-right">
                                        <feather-icon icon="XIcon" />
                                    </span>
                                </b-form-checkbox>
                            </div>
                            <div class="ml-1">
                                <span
                                    v-if="ussdBlockStartDate"
                                    class="d-block font-weight-bold font-medium-1"
                                    >{{ ussdBlockStartDate }}</span
                                >
                                <span
                                    v-if="ussdBlockEndDate"
                                    class="d-block font-weight-bold font-medium-1"
                                    >{{ ussdBlockEndDate }}</span
                                >
                            </div>

                            <!-- VERIFICATION MODAL 1 -->
                            <b-modal
                                id="modal-multi-1"
                                ref="firstVerificationModal"
                                size="md"
                                :title="$t('ussd.modal_title')"
                                no-stacking
                            >
                                <!--          DATE START          -->
                                <label for="dateStart">{{ $t('titles.start_date') }}</label>
                                <flat-pickr
                                    v-model="body.start"
                                    :config="{
                                        enableTime: true,
                                        allowInput: true,
                                        time_24hr: true
                                    }"
                                    autocomplete="off"
                                    class="form-control mb-1"
                                    name="date"
                                    :placeholder="$t('choose.date')"
                                    id="dateStart"
                                />

                                <!--          DATE END          -->
                                <label for="dateStart">{{ $t('titles.end_date') }}</label>
                                <flat-pickr
                                    v-model="body.end"
                                    :config="{
                                        enableTime: true,
                                        allowInput: true,
                                        time_24hr: true
                                    }"
                                    autocomplete="off"
                                    class="form-control"
                                    name="date"
                                    :placeholder="$t('choose.date')"
                                    id="dateEnd"
                                />
                                <!--                    @on-close="changeRange"-->
                                <b-card-text
                                    class="my-2 text-center font-weight-bold font-medium-2"
                                >
                                    <span v-if="!ussdStatus">{{
                                        $t('ussd.off_verification_text1')
                                    }}</span>
                                    <span v-else>{{ $t('ussd.on_verification_text1') }}</span>
                                </b-card-text>
                                <template #modal-footer>
                                    <b-button @click="closeModals" variant="danger">
                                        {{ $t('button.cancel') }}
                                    </b-button>
                                    <b-button
                                        :disabled="!body.start || !body.end"
                                        v-b-modal.modal-multi-2
                                        variant="success"
                                    >
                                        {{ $t('button.confirm') }}
                                    </b-button>
                                </template>
                            </b-modal>

                            <!-- VERIFICATION MODAL 2 -->
                            <b-modal
                                id="modal-multi-2"
                                ref="secondVerificationModal"
                                :title="$t('ussd.modal_title')"
                            >
                                <b-card-text
                                    class="my-2 text-center font-weight-bold font-medium-2"
                                >
                                    <span v-if="!ussdStatus">{{
                                        $t('ussd.off_verification_text2')
                                    }}</span>
                                    <span v-else>{{ $t('ussd.on_verification_text2') }}</span>
                                </b-card-text>
                                <b-card-text
                                    v-if="!ussdStatus"
                                    class="my-2 text-center font-weight-bold font-medium-2"
                                    >{{
                                        $t('ussd.time_duration', {
                                            start: body.start,
                                            end: body.end
                                        })
                                    }}</b-card-text
                                >
                                <template #modal-footer>
                                    <b-button @click="closeModals" variant="danger">
                                        {{ $t('button.cancel') }}
                                    </b-button>
                                    <b-button @click="triggerUSSD" variant="success">
                                        {{ $t('button.confirm') }}
                                    </b-button>
                                </template>
                            </b-modal>
                        </div>

                        <div class="d-flex align-items-center">
                            <b-button @click="refreshButton" variant="outline-primary" class="mr-1">
                                <feather-icon icon="RefreshCwIcon" size="16" />
                            </b-button>
                            <v-select
                                v-model="setIntervalValue"
                                :options="setIntervalList"
                                style="
                                    flex-grow: 1;
                                    height: 37px;
                                    max-width: 150px;
                                    min-width: 150px;
                                "
                                @input="clearIntervalAndSetNew"
                                :clearable="false"
                            />
                        </div>
                    </div>
                    <!--                <div class="col-12 col-md-4">-->
                    <!--                </div>-->
                </div>

                <div class="col-12">
                    <b-tabs content-class="mt-2" justified>
                        <b-tab
                            v-for="item in queueList"
                            :key="item.id"
                            :title="`${item.name.toUpperCase()} [${item.tasks}]`"
                            :title-link-class="
                                matchColorsForTasks(item.tasks, item.load_half, item.load_limit)
                            "
                            @click="fetchServerListByClick(item.id)"
                        >
                        </b-tab>
                    </b-tabs>
                </div>
            </div>
            <hr />
            <progress-linear v-if="requestPending" />
            <div v-else class="row">
                <div class="col-12">
                    <h2 class="mt-1">Server List</h2>
                </div>
                <div
                    class="col-12 col-sm-6 col-md-4 col-lg-3"
                    v-for="server in serverList"
                    :key="server.id"
                >
                    <b-card
                        v-if="server.status"
                        class="cursor-pointer"
                        :class="server.status.isActive ? 'active' : 'not-active'"
                    >
                        <div class="d-flex align-items-center mb-05 position-relative">
                            <!--                        <div v-if="!server.status.isActive" class="status-detector"></div>-->
                            <h3 class="mb-0 mx-auto text-info">
                                {{ server.host }} : {{ server.port }}
                            </h3>
                        </div>
                        <div class="d-flex align-items-center mb-05">
                            <span>Token:</span>
                            <small class="mb-0 ml-05 text__overflow text-secondary">{{
                                server.token
                            }}</small>
                        </div>
                        <div class="d-flex align-items-center mb-05">
                            <span>Type Queue:</span>
                            <h5 class="mb-0 ml-05">
                                <b-badge variant="primary text-uppercase">{{
                                    server.status.typeQueue
                                }}</b-badge>
                            </h5>
                        </div>

                        <div class="d-flex align-items-center mb-05">
                            <span>Active Workers:</span>
                            <h5 class="mb-0 ml-05">{{ server.status.activeWorkers }}</h5>
                        </div>

                        <div
                            class="control-actions d-flex align-items-center justify-content-between"
                        >
                            <b-button
                                class="w-100"
                                :variant="
                                    server.status.isActive ? 'outline-danger' : 'outline-success'
                                "
                                v-b-modal="`server-${server.id}`"
                            >
                                <feather-icon
                                    :class="server.status.isActive ? 'text-danger' : 'text-success'"
                                    :icon="server.status.isActive ? 'PauseIcon' : 'PlayIcon'"
                                    size="20"
                                />
                            </b-button>
                            <b-button
                                v-if="!server.status.isActive"
                                v-b-modal="`clean-server-${server.id}`"
                                variant="outline-info"
                                class="clean__btn"
                            >
                                <CleanIcon />
                            </b-button>
                        </div>

                        <!--         PLAY/PAUSE MODAL          -->
                        <b-modal
                            :id="`server-${server.id}`"
                            centered
                            :title="
                                server.status.isActive
                                    ? $t('titles.stop_server')
                                    : $t('titles.play_server')
                            "
                        >
                            <p class="my-4" v-if="server.status.isActive">
                                {{ $t('titles.server_actions_verification_stop') }}?
                                <span class="d-block mt-1 font-medium-4"
                                    ><strong>{{ server.host }} : {{ server.port }}</strong></span
                                >
                            </p>
                            <p class="my-4" v-else>
                                {{ $t('titles.server_actions_verification_start') }}?
                                <span class="d-block mt-1 font-medium-4"
                                    ><strong>{{ server.host }} : {{ server.port }}</strong></span
                                >
                            </p>

                            <template #modal-footer="{ cancel }">
                                <div class="d-flex align-items-center justify-content-end">
                                    <b-button @click="cancel()" variant="danger">{{
                                        $t('titles.cancel')
                                    }}</b-button>
                                    <b-button
                                        @click="triggerServer(server)"
                                        class="ml-1"
                                        variant="success"
                                        >{{ $t('titles.yes') }}</b-button
                                    >
                                </div>
                            </template>
                        </b-modal>

                        <!--         CLEAN MODAL          -->
                        <b-modal
                            :id="`clean-server-${server.id}`"
                            centered
                            :title="$t('titles.clean_queue')"
                        >
                            <p class="my-4">
                                {{ $t('titles.server_actions_verification_clean') }}?
                                <span class="d-block mt-1 font-medium-4"
                                    ><strong>{{ server.host }} : {{ server.port }}</strong></span
                                >
                            </p>

                            <template #modal-footer="{ cancel }">
                                <div class="d-flex align-items-center justify-content-end">
                                    <b-button @click="cancel()" variant="danger">{{
                                        $t('titles.cancel')
                                    }}</b-button>
                                    <b-button
                                        @click="cleanQueue(server)"
                                        class="ml-1"
                                        variant="success"
                                        >{{ $t('titles.yes') }}
                                    </b-button>
                                </div>
                            </template>
                        </b-modal>
                    </b-card>

                    <b-card v-else class="cursor-pointer secondary-bg">
                        <div class="d-flex align-items-center mb-05 position-relative">
                            <h3 class="mb-0 mx-auto text-info">
                                {{ server.host }} : {{ server.port }}
                            </h3>
                        </div>
                        <div class="d-flex align-items-center mb-05">
                            <span>Token:</span>
                            <small class="mb-0 ml-05 text__overflow text-secondary">{{
                                server.token
                            }}</small>
                        </div>
                        <div class="d-flex align-items-center mb-05">
                            <span>Type Queue:</span>
                            <h5 class="mb-0 ml-05">
                                <b-badge variant="primary text-uppercase"
                                    >service is not active</b-badge
                                >
                            </h5>
                        </div>

                        <div class="d-flex align-items-center mb-05">
                            <span>Active Workers:</span>
                            <h5 class="mb-0 ml-05">service is no active</h5>
                        </div>

                        <div class="control-actions">
                            <b-button
                                class="w-100"
                                variant="outline-secondary"
                                v-b-modal="`server-${server.id}`"
                                disabled
                            >
                                <feather-icon class="text-secondary" icon="XIcon" size="20" />
                            </b-button>
                        </div>

                        <b-modal :id="`server-${server.id}`" centered title="service is not active">
                            <p class="my-4">
                                {{ $t('titles.server_actions_verification_start') }}?
                                <span class="d-block mt-1 font-medium-4"
                                    ><strong>{{ server.host }} : {{ server.port }}</strong></span
                                >
                            </p>

                            <template #modal-footer="{ cancel }">
                                <div class="d-flex align-items-center justify-content-end">
                                    <b-button @click="cancel()" variant="danger">{{
                                        $t('titles.cancel')
                                    }}</b-button>
                                    <b-button
                                        @click="triggerServer(server)"
                                        class="ml-1"
                                        variant="success"
                                        >{{ $t('titles.yes') }}</b-button
                                    >
                                </div>
                            </template>
                        </b-modal>
                    </b-card>
                </div>
            </div>

            <!--  PAGINATION  -->
            <b-row>
                <b-col class="offset-3" cols="9">
                    <b-pagination
                        v-if="showPagination"
                        v-model="pagination.current"
                        :per-page="pagination.perPage"
                        :total-rows="pagination.totalItem"
                        class="float-right mr-4 mb-0"
                        first-number
                        last-number
                    />
                </b-col>
            </b-row>
        </div>
        <div v-else>
            <h3 class="text-center">{{ $t('has_not_permission') }}</h3>
        </div>
    </div>
</template>

<style scoped lang="scss">
.control {
    &-actions {
    }
}
.ml-05 {
    margin-left: 0.5rem;
}

.mb-05 {
    margin-bottom: 0.5rem;
}

.text__overflow {
    width: auto;
    overflow-x: hidden;
}

.card.active {
    border: 2px solid var(--success);
}

.card.not-active {
    border: 2px solid var(--danger);
}

.status-detector {
    position: absolute;
    top: 0;
    right: 0;
    width: 10px;
    height: 10px;
    border: 2px solid var(--danger);
    background: var(--danger);
    border-radius: 50%;
}

.secondary-bg {
    border: 2px solid var(--secondary);
}

.clean__btn {
    height: 44px;
    padding: 0 1rem;
    margin-left: 1rem;
}

.switch__button {
    //padding-left: 1rem;
    //position: absolute;
    //top: 2.5rem;
    //z-index: 123;
}

//@media only screen and (max-width: 1199px) {
//    .switch__button {
//        left: 280px;
//    }
//}
</style>
