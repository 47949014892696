<script>
import PageTitle from '@/components/PageTitle';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear';
import {
    BButton,
    BCol,
    BFormGroup,
    BFormInput,
    BModal,
    BPagination,
    BRow,
    BTable
} from 'bootstrap-vue';
import TableActionBtns from '@/components/TableActionBtns';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import {
    checkLang,
    generateIconColorToBoolean,
    generateIconToBoolean,
    updateObjectToNew
} from '@/util/helper';
import api from '@/services/api';
import { checkLocales } from '@/util/lang';
import vSelect from 'vue-select';

export default {
    name: 'AppKubeClusterV2',
    components: {
        TableActionBtns,
        ProgressLinear,
        PageTitle,
        BButton,
        BTable,
        BModal,
        BCol,
        BFormGroup,
        BRow,
        BFormInput,
        BPagination,
        vSelect
    },
    mixins: [CRUDPageMixin],
    data() {
        return {
            items: [],
            dataForModal: {
                mark: '',
                name: '',
                server_main: null,
                server_subs: []
            },
            serverList: [],
            filteredServerList: [],
            pagination: {
                current: 1,
                totalItem: '',
                perPage: 20
            },
            localLang: localStorage.getItem('lang'),
            requestPending: false
        };
    },

    watch: {
        '$route.query': {
            async handler(query) {
                await this.$store.dispatch('me/getUserMe');
                if (this.$store.getters['me/hasPageAccess']) {
                    this.pagination.current = query.page || 1;
                    await this.getAll();
                    // this.getAllServers();
                }
            },
            deep: true,
            immediate: true
        },

        'pagination.current': {
            handler(page) {
                let newPage = page;
                if (this.pagination.current > this.pagination.totalItem) {
                    newPage = 1;
                }
                this.replaceRouter({
                    ...this.query,
                    page: newPage
                });
            }
        }
    },

    computed: {
        fields() {
            return [
                // {
                //     key: 'id',
                //     label: '#'
                // },
                {
                    key: 'mark',
                    label: 'mark'
                },
                {
                    key: 'name',
                    label: this.$t('titles.title')
                },
                {
                    key: 'servers',
                    label: this.$t('titles.kube-servers')
                },
                {
                    key: 'actions',
                    label: ''
                }
            ];
        },
        query() {
            return Object.assign({}, this.$route.query);
        },

        hasItems() {
            return this.items && this.items.length > 0;
        },

        showPagination() {
            return this.hasItems && !this.requestPending;
        }
    },

    methods: {
        checkLocales,
        checkLang,

        async getAll() {
            const body = {
                page: this.pagination.current,
                limit: this.pagination.perPage
            };

            try {
                const { data } = await api.kubeServersApiV2.fetchAllClusters(body);
                this.items = data.data.list;
                this.items.forEach((item) => {
                    const mainIndex = item.servers.findIndex((server) => server.is_main);
                    if (mainIndex !== -1 && mainIndex !== 0) {
                        const mainServer = item.servers.splice(mainIndex, 1)[0];
                        item.servers.unshift(mainServer);
                    }
                });
                this.pagination = data.data.pagination;
            } catch (e) {
                console.error(e);
            }
        },

        async getAllServers() {
            const body = {
                page: 1,
                limit: 50
            };

            try {
                const { data } = await api.kubeServersApiV2.fetchAllItems(body);
                this.serverList = data.data.list.filter((item) => {
                    if (
                        (item['cluster'] !== null &&
                            this.updatingItem !== null &&
                            this.updatingItem.servers.find((obj) => obj.id === item.id)) ||
                        item['cluster'] === null
                    ) {
                        return item;
                    }
                });
                this.filteredServerList = this.serverList;
            } catch (e) {
                console.error(e);
            }
        },

        async getOneItem(item) {
            this.updatingItem = item;
            await api.kubeServersApiV2
                .fetchOneCluster(item.id)
                .then((response) => {
                    this.isOpenModal = true;

                    // merge Object for need data
                    const res = response.data.data;
                    Object.keys(res).forEach((item) => {
                        if (item in this.dataForModal) {
                            this.dataForModal[item] = res[item];
                        }
                    });
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(async () => {
                    await this.getAllServers();
                });
        },

        async createItem(body) {
            await api.kubeServersApiV2
                .createCluster(body)
                .then(async ({ data }) => {
                    this.items.push(data);
                    await this.getAll();
                    this.isOpenModal = false;
                    this.$toast.success(this.$t('success.added'));
                })
                .catch((error) => {
                    this.$toast.error(
                        error.response?.data?.message || error.message || this.$t('error.smth')
                    );
                });
        },

        async updateItem(id, body) {
            await api.kubeServersApiV2
                .updateCluster(id, body)
                .then(async ({ data }) => {
                    this.items.find((item) => {
                        if (item.id === data.id) {
                            updateObjectToNew(item, data);
                        }
                    });
                    await this.getAll();
                    return Promise.resolve();
                })
                .catch((e) => {
                    return Promise.reject(e);
                });
        },

        async deleteItem(id) {
            await api.kubeServersApiV2
                .deleteCluster(id)
                .then(async () => {
                    await this.getAll();
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async submitModal() {
            this.$refs.createUpdateModal.validate().then(async (success) => {
                if (success) {
                    let body = this.dataForModal;

                    const config = {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    };

                    try {
                        if (this.updatingItem) {
                            await this.updateItem(this.updatingItem.id, body, config);
                            this.$toast.success(this.$t('success.updated.title'));
                        } else {
                            await this.createItem(body, config);
                            this.$toast.success(this.$t('success.added'));
                        }

                        this.isOpenModal = false;
                    } catch (error) {
                        this.$toast.error(this.$t('error.smth'));
                    }
                }
            });
        },

        filterServerList(value) {
            if (value === null || value === undefined) {
                this.filteredServerList = this.serverList;
            } else {
                this.filteredServerList = this.serverList.filter((item) => item.id !== value);
                this.dataForModal.server_subs = [];
            }
        },

        changePerPage(perPage) {
            this.pagination = {
                perPage,
                page: 1
            };
            this.getAll();
        },

        beforeClose() {
            this.filteredServerList = this.serverList;
            this.onCloseModal();
        },

        replaceRouter(query) {
            this.$router.replace({ query }).catch(() => {});
        },

        generateIconToBoolean,
        generateIconColorToBoolean
    }
};
</script>

<template>
    <div>
        <div v-if="$store.getters['me/hasPageAccess']">
            <!--    v-if="hasAccess('coin_award','view')"-->
            <page-title>
                <template #title> {{ $t('titles.cluster') }} [V2]</template>
                <template v-if="hasAccess('users', 'create')" #button>
                    <b-button
                        @click="getAllServers"
                        v-b-modal.createUpdateModal
                        class="ml-auto"
                        variant="success"
                    >
                        {{ $t('button.add') }}
                        <feather-icon icon="PlusIcon" />
                    </b-button>
                </template>
            </page-title>

            <!--  TABLE  -->
            <div class="card">
                <progress-linear v-if="requestPending" />
                <b-table
                    v-else
                    :fields="fields"
                    :items="items"
                    :responsive="true"
                    borderless
                    class="news__table"
                    outlined
                    striped
                >
                    <template #cell(servers)="{ item }">
                        <b-badge
                            v-for="server in item.servers"
                            :key="server.id"
                            :variant="server['is_main'] ? 'primary' : 'secondary'"
                            class="mr-1"
                        >
                            {{ server.mark.toUpperCase() }}
                        </b-badge>
                    </template>

                    <template #cell(actions)="{ item, index }">
                        <div class="d-flex justify-content-end">
                            <!--                        <b-button-->
                            <!--                            v-b-tooltip.hover.top="'RECONNECT'"-->
                            <!--                            v-b-modal.reconnect-modal-->
                            <!--                            class="btn-icon"-->
                            <!--                            variant="flat-info"-->
                            <!--                            @click="openReconnectModal(item)"-->
                            <!--                        >-->
                            <!--                            <feather-icon icon="RefreshCwIcon" />-->
                            <!--                        </b-button>-->
                            <table-action-btns
                                :delete-access="{ section: 'collector', permission: 'delete' }"
                                :delete-handler="deleteItem"
                                :index="index"
                                :item="item"
                                :update-access="{ section: 'collector', permission: 'update' }"
                                @openModal="getOneItem(item)"
                            />
                        </div>
                    </template>
                </b-table>
            </div>

            <!--  CREATE & UPDATE MODALS  -->
            <b-modal
                id="createUpdateModal"
                v-model="isOpenModal"
                :hide-footer="isViewModal"
                :no-close-on-backdrop="true"
                :title="updatingItem ? $t('edit.kube_server') : $t('add.kube_server')"
                cancel-variant="danger"
                centered
                size="md"
                @hidden="beforeClose"
            >
                <ValidationObserver ref="createUpdateModal">
                    <b-row>
                        <!--    MARK    -->
                        <b-col cols="12">
                            <label>{{ $t('titles.key') }}</label>
                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="$t('titles.key')"
                                rules="required"
                            >
                                <b-form-group>
                                    <b-form-input
                                        v-model="dataForModal.mark"
                                        :placeholder="$t('enter.key')"
                                        :state="errors.length > 0 ? false : null"
                                        size="md"
                                    />
                                </b-form-group>
                                <span class="validation__red">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </b-col>

                        <!--    NAME    -->
                        <b-col cols="12">
                            <label>{{ $t('titles.title') }}</label>
                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="$t('titles.title')"
                                rules="required"
                            >
                                <b-form-group>
                                    <b-form-input
                                        v-model="dataForModal.name"
                                        :placeholder="$t('enter.title')"
                                        :state="errors.length > 0 ? false : null"
                                        size="md"
                                    />
                                </b-form-group>
                                <span class="validation__red">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </b-col>

                        <!--    SERVER MAIN    -->
                        <b-col cols="12">
                            <label>{{ $t('titles.server_main') }}</label>
                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="$t('titles.server_main')"
                                rules="required"
                            >
                                <b-form-group>
                                    <v-select
                                        v-model="dataForModal.server_main"
                                        :clearable="true"
                                        :placeholder="$t('titles.server_main')"
                                        :options="serverList"
                                        :reduce="(option) => option.id"
                                        label="name"
                                        :multiple="false"
                                        @input="filterServerList"
                                    >
                                        <template v-slot:option="option">
                                            {{ option.mark + ' ' + `[${option['queue_trick']}]` }}
                                        </template>

                                        <template v-slot:selected-option="option">
                                            {{ option.mark + ' ' + `[${option['queue_trick']}]` }}
                                        </template>
                                    </v-select>
                                </b-form-group>
                                <span class="validation__red">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </b-col>

                        <!--    SERVER SUBS    -->
                        <b-col cols="12">
                            <label>{{ $t('titles.server_subs') }}</label>
                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="$t('titles.server_subs')"
                                rules="required"
                            >
                                <b-form-group>
                                    <v-select
                                        v-model="dataForModal.server_subs"
                                        :clearable="true"
                                        :placeholder="$t('titles.server_subs')"
                                        :options="filteredServerList"
                                        :reduce="(option) => option.id"
                                        label="name"
                                        :multiple="false"
                                    >
                                        <template v-slot:option="option">
                                            {{ option.mark + ' ' + `[${option['queue_trick']}]` }}
                                        </template>

                                        <template v-slot:selected-option="option">
                                            {{ option.mark + ' ' + `[${option['queue_trick']}]` }}
                                        </template>
                                    </v-select>
                                </b-form-group>
                                <span class="validation__red">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                </ValidationObserver>

                <template #modal-footer>
                    <b-button variant="success" @click="submitModal">
                        {{ modalSubmitBtnText }}
                    </b-button>
                </template>
            </b-modal>

            <!--  PAGINATION  -->
            <b-row>
                <b-col class="offset-3" cols="9">
                    <b-pagination
                        v-if="showPagination"
                        v-model="pagination.current"
                        :per-page="pagination.perPage"
                        :total-rows="pagination.totalItem"
                        class="float-right mr-4 mb-0"
                        first-number
                        last-number
                    />
                </b-col>
            </b-row>
        </div>

        <div v-else>
            <h3 class="text-center">{{ $t('has_not_permission') }}</h3>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

::v-deep .vs__dropdown-menu {
    min-height: auto;
    //height: 100px;
}

.card-body {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100%;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.table-badge__item {
    margin: 4px;
}
</style>
