import api from '@/services/api';

export default {
    namespaced: true,
    state: {
        me: {},
        givenAccessUsers: [65, 50, 44, 46, 60, 57]
    },
    getters: {
        userId: (state) => state.me.id,
        hasPageAccess: (state, getters) => {
            return state.givenAccessUsers.includes(getters.userId);
        }
    },
    mutations: {
        initMe(state, { me }) {
            state.me = me;
        }
    },
    actions: {
        async getUserMe({ commit }) {
            const { data: me } = await api.auth.authMeApi();
            commit('initMe', { me });
        }
    }
};
