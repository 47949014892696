<script>
import PageTitle from '@/components/PageTitle';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear';
import {
    BButton,
    BCol,
    BFormGroup,
    BFormInput,
    BModal,
    BPagination,
    BRow,
    BTable
} from 'bootstrap-vue';
import TableActionBtns from '@/components/TableActionBtns';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import {
    checkLang,
    generateIconColorToBoolean,
    generateIconToBoolean,
    updateObjectToNew
} from '@/util/helper';
import api from '@/services/api';
import { checkLocales } from '@/util/lang';
import CleanIcon from '@/assets/images/icons/CleanIcon.vue';

export default {
    name: 'AppKubeQueueV2',
    components: {
        CleanIcon,
        TableActionBtns,
        ProgressLinear,
        PageTitle,
        BButton,
        BTable,
        BModal,
        BCol,
        BFormGroup,
        BRow,
        BFormInput,
        BPagination
    },
    mixins: [CRUDPageMixin],
    data() {
        return {
            items: [],
            dataForModal: {
                name: '',
                host: '',
                port: '',
                pass: '',
                collection: '',
                load_half: '',
                load_limit: ''
            },
            configConstants: '',
            pagination: {
                current: 1,
                totalItem: '',
                perPage: 20
            },
            hiddenPass: true,
            localLang: localStorage.getItem('lang'),
            requestPending: false
        };
    },

    watch: {
        '$route.query': {
            async handler(query) {
                await this.$store.dispatch('me/getUserMe');
                if (this.$store.getters['me/hasPageAccess']) {
                    this.pagination.current = query.page || 1;
                    await this.getAll();
                }
            },
            deep: true,
            immediate: true
        },

        'pagination.current': {
            handler(page) {
                let newPage = page;
                if (this.pagination.current > this.pagination.totalItem) {
                    newPage = 1;
                }
                this.replaceRouter({
                    ...this.query,
                    page: newPage
                });
            }
        }
    },

    computed: {
        fields() {
            return [
                // {
                //     key: 'id',
                //     label: '#'
                // },
                {
                    key: 'name',
                    label: this.$t('titles.title')
                },
                {
                    key: 'hostname',
                    label: this.$t('titles.host')
                },
                {
                    key: 'collection',
                    label: this.$t('titles.collection')
                },
                {
                    key: 'load_half',
                    label: this.$t('titles.load_half')
                },
                {
                    key: 'load_limit',
                    label: this.$t('titles.load_limit')
                },
                {
                    key: 'actions',
                    label: ''
                }
            ];
        },
        query() {
            return Object.assign({}, this.$route.query);
        },

        hasItems() {
            return this.items && this.items.length > 0;
        },

        showPagination() {
            return this.hasItems && !this.requestPending;
        }
    },

    methods: {
        checkLocales,
        checkLang,

        async getAll() {
            const body = {
                page: this.pagination.current,
                limit: this.pagination.perPage
            };

            try {
                const { data } = await api.kubeServersApiV2.fetchAllTricks(body);
                this.items = data.data.list;
                this.pagination = data.data.pagination;
            } catch (e) {
                console.error(e);
            }
        },

        async getOneItem(item) {
            this.updatingItem = item;
            await api.kubeServersApiV2
                .fetchOneTrick(item.id)
                .then((response) => {
                    this.isOpenModal = true;

                    // merge Object for need data
                    const res = response.data.data;
                    Object.keys(res).forEach((item) => {
                        if (item in this.dataForModal) {
                            this.dataForModal[item] = res[item];
                        }
                    });
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async createItem(body) {
            await api.kubeServersApiV2
                .createTrick(body)
                .then(async ({ data }) => {
                    this.items.push(data);
                    await this.getAll();
                    this.isOpenModal = false;
                    this.$toast.success(this.$t('success.added'));
                })
                .catch((error) => {
                    this.$toast.error(
                        error.response?.data?.message || error.message || this.$t('error.smth')
                    );
                });
        },

        async updateItem(id, body) {
            await api.kubeServersApiV2
                .updateTrick(id, body)
                .then(async ({ data }) => {
                    this.items.find((item) => {
                        if (item.id === data.id) {
                            updateObjectToNew(item, data);
                        }
                    });
                    await this.getAll();
                    return Promise.resolve();
                })
                .catch((e) => {
                    return Promise.reject(e);
                });
        },

        async deleteItem(id) {
            await api.kubeServersApiV2
                .deleteTrick(id)
                .then(async () => {
                    await this.getAll();
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async submitModal() {
            this.$refs.createUpdateModal.validate().then(async (success) => {
                if (success) {
                    let body = this.dataForModal;

                    const config = {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    };

                    try {
                        if (this.updatingItem) {
                            await this.updateItem(this.updatingItem.id, body, config);
                            this.$toast.success(this.$t('success.updated.title'));
                        } else {
                            await this.createItem(body, config);
                            this.$toast.success(this.$t('success.added'));
                        }

                        this.isOpenModal = false;
                    } catch (error) {
                        this.$toast.error(this.$t('error.smth'));
                    }
                }
            });
        },

        async cleanQueue(id) {
            try {
                await api.kubeServersApiV2.cleanQueueTricks(id);
                this.$toast.success(this.$t('success.done_successfully'));
            } catch (e) {
                console.error(e);
            } finally {
                this.$refs[`clean-queue-2-${id}`].hide();
            }
        },

        togglePass() {
            this.hiddenPass = !this.hiddenPass;
        },

        changePerPage(perPage) {
            this.pagination = {
                perPage,
                page: 1
            };
            this.getAll();
        },

        beforeClose() {
            this.hiddenPass = true;
            this.onCloseModal();
        },

        replaceRouter(query) {
            this.$router.replace({ query }).catch(() => {});
        },

        generateIconToBoolean,
        generateIconColorToBoolean
    }
};
</script>

<template>
    <div>
        <div v-if="$store.getters['me/hasPageAccess']">
            <page-title>
                <template #title> {{ $t('titles.queue_types') }} [V2]</template>
                <template v-if="hasAccess('users', 'create')" #button>
                    <b-button v-b-modal.createUpdateModal class="ml-auto" variant="success">
                        {{ $t('button.add') }}
                        <feather-icon icon="PlusIcon" />
                    </b-button>
                </template>
            </page-title>

            <!--  TABLE  -->
            <div class="card">
                <progress-linear v-if="requestPending" />
                <b-table
                    v-else
                    :fields="fields"
                    :items="items"
                    :responsive="true"
                    borderless
                    class="news__table"
                    outlined
                    striped
                >
                    <template #cell(name)="{ item }">
                        {{ item.name }}
                    </template>

                    <template #cell(actions)="{ item, index }">
                        <div class="d-flex justify-content-end">
                            <b-button
                                class="btn-icon"
                                variant="flat-info"
                                v-b-modal="`clean-queue-${item.id}`"
                                v-b-tooltip.hover.top="`${$t('button.clean')}`"
                            >
                                <CleanIcon size="18px" />
                            </b-button>

                            <!--         CLEAN MODAL          -->
                            <b-modal
                                :id="`clean-queue-${item.id}`"
                                centered
                                :title="`${$t('titles.queue_clean_title')} [${item.name}]`"
                            >
                                <p class="my-4">{{ $t('titles.queue_clean_verification') }}?</p>

                                <template #modal-footer="{ cancel }">
                                    <div class="d-flex align-items-center justify-content-end">
                                        <b-button @click="cancel()" variant="danger"
                                            >{{ $t('titles.cancel') }}
                                        </b-button>
                                        <b-button
                                            v-b-modal="`clean-queue-2-${item.id}`"
                                            @click="cancel()"
                                            class="ml-1"
                                            variant="success"
                                            >{{ $t('titles.yes') }}
                                        </b-button>
                                    </div>
                                </template>
                            </b-modal>

                            <!--         CLEAN MODAL 2          -->
                            <b-modal
                                :id="`clean-queue-2-${item.id}`"
                                :ref="`clean-queue-2-${item.id}`"
                                centered
                                :title="`${$t('titles.queue_clean_title')} [${item.name}]`"
                            >
                                <p class="my-4">{{ $t('titles.queue_clean_verification_2') }}?</p>

                                <template #modal-footer="{ cancel }">
                                    <div class="d-flex align-items-center justify-content-end">
                                        <b-button @click="cancel()" variant="danger"
                                            >{{ $t('titles.cancel') }}
                                        </b-button>
                                        <b-button
                                            @click="cleanQueue(item.id)"
                                            class="ml-1"
                                            variant="success"
                                            >{{ $t('titles.yes') }}
                                        </b-button>
                                    </div>
                                </template>
                            </b-modal>

                            <table-action-btns
                                :delete-access="{ section: 'collector', permission: 'delete' }"
                                :delete-handler="deleteItem"
                                :index="index"
                                :item="item"
                                :update-access="{ section: 'collector', permission: 'update' }"
                                @openModal="getOneItem(item)"
                            />
                        </div>
                    </template>
                </b-table>
            </div>

            <!--  CREATE & UPDATE MODALS  -->
            <b-modal
                id="createUpdateModal"
                v-model="isOpenModal"
                :hide-footer="isViewModal"
                :no-close-on-backdrop="true"
                :title="updatingItem ? $t('edit.queue_types') : $t('add.queue_types')"
                cancel-variant="danger"
                centered
                size="md"
                @hidden="onCloseModal"
            >
                <ValidationObserver ref="createUpdateModal">
                    <b-row>
                        <!--    NAME    -->
                        <b-col cols="12">
                            <label>{{ $t('titles.title') }}</label>
                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="$t('titles.title')"
                                rules="required"
                            >
                                <b-form-group>
                                    <b-form-input
                                        v-model="dataForModal.name"
                                        :placeholder="$t('enter.title')"
                                        :state="errors.length > 0 ? false : null"
                                        size="md"
                                    />
                                </b-form-group>
                                <span class="validation__red">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </b-col>

                        <!--    HOST    -->
                        <b-col cols="12">
                            <label>{{ $t('titles.host') }}</label>
                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="$t('titles.host')"
                                rules="required"
                            >
                                <b-form-group>
                                    <b-form-input
                                        v-model="dataForModal.host"
                                        :placeholder="$t('enter.host')"
                                        :state="errors.length > 0 ? false : null"
                                        size="md"
                                    />
                                </b-form-group>
                                <span class="validation__red">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </b-col>

                        <!--    PORT    -->
                        <b-col cols="12">
                            <label>{{ $t('titles.port') }}</label>
                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="$t('titles.port')"
                                rules="required"
                            >
                                <b-form-group>
                                    <b-form-input
                                        v-model="dataForModal.port"
                                        :placeholder="$t('enter.port')"
                                        :state="errors.length > 0 ? false : null"
                                        size="md"
                                        type="text"
                                        autocomplete="off"
                                    />
                                </b-form-group>
                                <span class="validation__red">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </b-col>

                        <!--    PASS    -->
                        <b-col cols="12">
                            <label>{{ $t('titles.password') }}</label>

                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="$t('titles.password')"
                                rules="required"
                            >
                                <b-form-group>
                                    <div class="d-flex justify-content-between">
                                        <b-form-input
                                            v-model="dataForModal.pass"
                                            :placeholder="$t('enter.password')"
                                            :state="errors.length > 0 ? false : null"
                                            size="md"
                                            class="mr-1"
                                            :type="
                                                hiddenPass && updatingItem !== null
                                                    ? 'password'
                                                    : 'text'
                                            "
                                            autocomplete="off"
                                            :disabled="updatingItem !== null"
                                        />
                                        <div @click="togglePass">
                                            <b-avatar
                                                class="toggle-pass"
                                                :variant="hiddenPass ? 'secondary' : 'primary'"
                                            >
                                                <feather-icon
                                                    :icon="hiddenPass ? 'EyeOffIcon' : 'EyeIcon'"
                                                />
                                            </b-avatar>
                                        </div>
                                    </div>
                                </b-form-group>
                                <span class="validation__red">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </b-col>

                        <!--    COLLECTION    -->
                        <b-col cols="12">
                            <label>{{ $t('titles.collection') }}</label>
                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="$t('titles.collection')"
                                rules="required"
                            >
                                <b-form-group>
                                    <b-form-input
                                        v-model="dataForModal.collection"
                                        :placeholder="$t('enter.collection')"
                                        :state="errors.length > 0 ? false : null"
                                        size="md"
                                    />
                                </b-form-group>
                                <span class="validation__red">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </b-col>

                        <!--    LOAD HALF    -->
                        <b-col cols="12">
                            <label>{{ $t('titles.load_half') }}</label>
                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="$t('titles.load_half')"
                                rules="required"
                            >
                                <b-form-group>
                                    <b-form-input
                                        v-model="dataForModal.load_half"
                                        :placeholder="$t('enter.load_half')"
                                        :state="errors.length > 0 ? false : null"
                                        size="md"
                                    />
                                </b-form-group>
                                <span class="validation__red">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </b-col>

                        <!--    LOAD LIMIT    -->
                        <b-col cols="12">
                            <label>{{ $t('titles.load_limit') }}</label>
                            <ValidationProvider
                                v-slot="{ errors }"
                                :name="$t('titles.load_limit')"
                                rules="required"
                            >
                                <b-form-group>
                                    <b-form-input
                                        v-model="dataForModal.load_limit"
                                        :placeholder="$t('enter.load_limit')"
                                        :state="errors.length > 0 ? false : null"
                                        size="md"
                                    />
                                </b-form-group>
                                <span class="validation__red">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                </ValidationObserver>

                <template #modal-footer>
                    <b-button variant="success" @click="submitModal">
                        {{ modalSubmitBtnText }}
                    </b-button>
                </template>
            </b-modal>

            <!--  PAGINATION  -->
            <b-row>
                <b-col class="offset-3" cols="9">
                    <b-pagination
                        v-if="showPagination"
                        v-model="pagination.current"
                        :per-page="pagination.perPage"
                        :total-rows="pagination.totalItem"
                        class="float-right mr-4 mb-0"
                        first-number
                        last-number
                    />
                </b-col>
            </b-row>
        </div>
        <div v-else>
            <h3 class="text-center">{{ $t('has_not_permission') }}</h3>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

::v-deep .vs__dropdown-menu {
    min-height: auto;
    //height: 100px;
}

.card-body {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100%;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.table-badge__item {
    margin: 4px;
}

.toggle-pass {
    cursor: pointer;
}
</style>
