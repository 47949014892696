var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$store.getters['me/hasPageAccess'])?_c('div',[_c('page-title',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('titles.queue_types'))+" [V2]")]},proxy:true},(_vm.hasAccess('users', 'create'))?{key:"button",fn:function(){return [_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.createUpdateModal",modifiers:{"createUpdateModal":true}}],staticClass:"ml-auto",attrs:{"variant":"success"}},[_vm._v(" "+_vm._s(_vm.$t('button.add'))+" "),_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)]},proxy:true}:null],null,true)}),_c('div',{staticClass:"card"},[(_vm.requestPending)?_c('progress-linear'):_c('b-table',{staticClass:"news__table",attrs:{"fields":_vm.fields,"items":_vm.items,"responsive":true,"borderless":"","outlined":"","striped":""},scopedSlots:_vm._u([{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal",value:(("clean-queue-" + (item.id))),expression:"`clean-queue-${item.id}`"},{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(("" + (_vm.$t('button.clean')))),expression:"`${$t('button.clean')}`",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon",attrs:{"variant":"flat-info"}},[_c('CleanIcon',{attrs:{"size":"18px"}})],1),_c('b-modal',{attrs:{"id":("clean-queue-" + (item.id)),"centered":"","title":((_vm.$t('titles.queue_clean_title')) + " [" + (item.name) + "]")},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var cancel = ref.cancel;
return [_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[_c('b-button',{attrs:{"variant":"danger"},on:{"click":function($event){return cancel()}}},[_vm._v(_vm._s(_vm.$t('titles.cancel'))+" ")]),_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal",value:(("clean-queue-2-" + (item.id))),expression:"`clean-queue-2-${item.id}`"}],staticClass:"ml-1",attrs:{"variant":"success"},on:{"click":function($event){return cancel()}}},[_vm._v(_vm._s(_vm.$t('titles.yes'))+" ")])],1)]}}],null,true)},[_c('p',{staticClass:"my-4"},[_vm._v(_vm._s(_vm.$t('titles.queue_clean_verification'))+"?")])]),_c('b-modal',{ref:("clean-queue-2-" + (item.id)),attrs:{"id":("clean-queue-2-" + (item.id)),"centered":"","title":((_vm.$t('titles.queue_clean_title')) + " [" + (item.name) + "]")},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var cancel = ref.cancel;
return [_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[_c('b-button',{attrs:{"variant":"danger"},on:{"click":function($event){return cancel()}}},[_vm._v(_vm._s(_vm.$t('titles.cancel'))+" ")]),_c('b-button',{staticClass:"ml-1",attrs:{"variant":"success"},on:{"click":function($event){return _vm.cleanQueue(item.id)}}},[_vm._v(_vm._s(_vm.$t('titles.yes'))+" ")])],1)]}}],null,true)},[_c('p',{staticClass:"my-4"},[_vm._v(_vm._s(_vm.$t('titles.queue_clean_verification_2'))+"?")])]),_c('table-action-btns',{attrs:{"delete-access":{ section: 'collector', permission: 'delete' },"delete-handler":_vm.deleteItem,"index":index,"item":item,"update-access":{ section: 'collector', permission: 'update' }},on:{"openModal":function($event){return _vm.getOneItem(item)}}})],1)]}}],null,false,2958105209)})],1),_c('b-modal',{attrs:{"id":"createUpdateModal","hide-footer":_vm.isViewModal,"no-close-on-backdrop":true,"title":_vm.updatingItem ? _vm.$t('edit.queue_types') : _vm.$t('add.queue_types'),"cancel-variant":"danger","centered":"","size":"md"},on:{"hidden":_vm.onCloseModal},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.submitModal}},[_vm._v(" "+_vm._s(_vm.modalSubmitBtnText)+" ")])]},proxy:true}],null,false,650379909),model:{value:(_vm.isOpenModal),callback:function ($$v) {_vm.isOpenModal=$$v},expression:"isOpenModal"}},[_c('ValidationObserver',{ref:"createUpdateModal"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.title')))]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.title'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":_vm.$t('enter.title'),"state":errors.length > 0 ? false : null,"size":"md"},model:{value:(_vm.dataForModal.name),callback:function ($$v) {_vm.$set(_vm.dataForModal, "name", $$v)},expression:"dataForModal.name"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3221944950)})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.host')))]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.host'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":_vm.$t('enter.host'),"state":errors.length > 0 ? false : null,"size":"md"},model:{value:(_vm.dataForModal.host),callback:function ($$v) {_vm.$set(_vm.dataForModal, "host", $$v)},expression:"dataForModal.host"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3228994193)})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.port')))]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.port'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":_vm.$t('enter.port'),"state":errors.length > 0 ? false : null,"size":"md","type":"text","autocomplete":"off"},model:{value:(_vm.dataForModal.port),callback:function ($$v) {_vm.$set(_vm.dataForModal, "port", $$v)},expression:"dataForModal.port"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,934238909)})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.password')))]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.password'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('b-form-input',{staticClass:"mr-1",attrs:{"placeholder":_vm.$t('enter.password'),"state":errors.length > 0 ? false : null,"size":"md","type":_vm.hiddenPass && _vm.updatingItem !== null
                                                ? 'password'
                                                : 'text',"autocomplete":"off","disabled":_vm.updatingItem !== null},model:{value:(_vm.dataForModal.pass),callback:function ($$v) {_vm.$set(_vm.dataForModal, "pass", $$v)},expression:"dataForModal.pass"}}),_c('div',{on:{"click":_vm.togglePass}},[_c('b-avatar',{staticClass:"toggle-pass",attrs:{"variant":_vm.hiddenPass ? 'secondary' : 'primary'}},[_c('feather-icon',{attrs:{"icon":_vm.hiddenPass ? 'EyeOffIcon' : 'EyeIcon'}})],1)],1)],1)]),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3988596478)})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.collection')))]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.collection'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                                var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":_vm.$t('enter.collection'),"state":errors.length > 0 ? false : null,"size":"md"},model:{value:(_vm.dataForModal.collection),callback:function ($$v) {_vm.$set(_vm.dataForModal, "collection", $$v)},expression:"dataForModal.collection"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2493411857)})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.load_half')))]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.load_half'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                                var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":_vm.$t('enter.load_half'),"state":errors.length > 0 ? false : null,"size":"md"},model:{value:(_vm.dataForModal.load_half),callback:function ($$v) {_vm.$set(_vm.dataForModal, "load_half", $$v)},expression:"dataForModal.load_half"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3270882801)})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.load_limit')))]),_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.load_limit'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                                var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":_vm.$t('enter.load_limit'),"state":errors.length > 0 ? false : null,"size":"md"},model:{value:(_vm.dataForModal.load_limit),callback:function ($$v) {_vm.$set(_vm.dataForModal, "load_limit", $$v)},expression:"dataForModal.load_limit"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2118832529)})],1)],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"offset-3",attrs:{"cols":"9"}},[(_vm.showPagination)?_c('b-pagination',{staticClass:"float-right mr-4 mb-0",attrs:{"per-page":_vm.pagination.perPage,"total-rows":_vm.pagination.totalItem,"first-number":"","last-number":""},model:{value:(_vm.pagination.current),callback:function ($$v) {_vm.$set(_vm.pagination, "current", $$v)},expression:"pagination.current"}}):_vm._e()],1)],1)],1):_c('div',[_c('h3',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t('has_not_permission')))])])])}
var staticRenderFns = []

export { render, staticRenderFns }