<script>
import { BButton, BCol, BFormGroup, BModal, BRow, BTable } from 'bootstrap-vue';
import { MERCHANT_ID } from '@/constants/names';
import { checkLocales } from '@/util/lang';
import FlatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/plugins/monthSelect/style.css';

import 'flatpickr/dist/flatpickr.css';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';
import 'flatpickr/dist/plugins/monthSelect/style.css';
import { subscriberStreakApi } from '@/services/subscriber/subscriber.streak.service';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear.vue';

export default {
    name: 'SubscriberStreak',
    components: { ProgressLinear, BTable, BButton, BModal, BRow, BCol, BFormGroup, FlatPickr },
    props: {
        phoneNumber: {
            type: [String, Number],
            default: null,
            required: true
        }
    },
    data() {
        return {
            month: null,
            amount: null,
            subscriberInfo: null,
            resetModal: false,
            config: {
                dateFormat: 'Y-m',
                plugins: [
                    new monthSelectPlugin({
                        shorthand: true,
                        dateFormat: 'Y-m',
                        altFormat: 'F Y'
                    })
                ]
            },
            requestPending: true
        };
    },
    watch: {
        phoneNumber: {
            immediate: true,
            handler(newData) {
                if (newData) {
                    this.getSubscriberStreak();
                }
            }
        }
    },
    // beforeRouteEnter(to, from, next) {
    //     next(async (vm) => {
    //         await vm.getSubscriberStreak();
    //     });
    // },
    computed: {
        fields() {
            return [
                {
                    key: 'level',
                    label: this.$t('titles.level')
                },
                {
                    key: 'limit',
                    label: this.$t('titles.limit')
                },
                {
                    key: 'prize',
                    label: this.$t('titles.prize')
                }
            ];
        }
    },
    methods: {
        checkLocales,
        async getSubscriberStreak() {
            this.requestPending = true;
            const body = {
                merchant_id: parseInt(MERCHANT_ID),
                month: this.month,
                phone: this.phoneNumber
            };
            try {
                const { data } = await subscriberStreakApi.fetchSubscriberStreak(body);
                this.subscriberInfo = data.data;
            } catch (e) {
                console.error(e);
            } finally {
                this.requestPending = false;
            }
        },

        async setScoreToSubscriber() {
            const body = {
                phone: this.phoneNumber,
                amount: this.amount,
                merchant_id: parseInt(MERCHANT_ID)
            };
            try {
                const { data } = await subscriberStreakApi.setScoreToSubscriber(body);
                this.$toast.success(this.$t('success.appointed_successfully'));
                this.amount = null;
                console.log(data);
            } catch (e) {
                console.error(e);
            } finally {
                await this.getSubscriberStreak();
            }
        },

        async resetSubscriberStreak() {
            const body = {
                phone: this.phoneNumber,
                merchant_id: parseInt(MERCHANT_ID)
            };
            try {
                await subscriberStreakApi.restartSubscriber(body);
                this.$toast.success(this.$t('success.done_successfully'));
                this.closeResetModal();
            } catch (e) {
                console.error(e);
            } finally {
                await this.getSubscriberStreak();
            }
        },

        openResetModal() {
            this.resetModal = true;
        },

        closeResetModal() {
            this.resetModal = false;
        }
    }
};
</script>

<template>
    <div>
        <b-row>
            <b-col class="col-lg-6 col-sm-6 col-12 order-2 order-sm-1">
                <b-card>
                    <progress-linear v-if="requestPending" />
                    <div v-else>
                        <div class="float-right mb-1" v-if="subscriberInfo">
                            <span class="font-medium-2 font-weight-bold font-weight-bold text-info">
                                {{ subscriberInfo.currentState }} / {{ subscriberInfo.maxLimit }}
                            </span>
                        </div>
                        <div>
                            <b-table
                                v-if="subscriberInfo"
                                striped
                                borderless
                                outlined
                                :responsive="true"
                                :fields="fields"
                                :items="subscriberInfo.levels"
                                class="news__table"
                            >
                                <template #cell(level)="{ index }">
                                    <span>{{ index + 1 }}</span>
                                </template>
                                <template #cell(prize)="{ item }">
                                    <span v-if="item.prize">{{
                                        checkLocales(item.prize.name)
                                    }}</span>
                                </template>
                            </b-table>
                        </div>
                    </div>
                </b-card>
            </b-col>
            <b-col class="mb-2 mb-sm-0 col-lg-6 col-sm-6 col-12 order-1 order-sm-2">
                <b-card>
                    <h3 class="mb-2">{{ $t('filter_title') }}</h3>
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <flat-pickr
                                v-model="month"
                                :config="config"
                                :placeholder="$t('choose.date')"
                                class="form-control"
                                name="date"
                                id="date"
                            />
                        </div>
                        <div class="col-12 col-md-6 mt-1 mt-md-0 d-flex justify-content-end">
                            <b-button @click="getSubscriberStreak" class="ml-2" variant="success"
                                >{{ $t('button.filter') }}
                            </b-button>
                        </div>
                    </div>
                </b-card>

                <b-card>
                    <h3 class="mb-2">{{ $t('titles.crediting') }}</h3>
                    <b-col cols="12" class="px-0">
                        <b-form-group>
                            <label for="amount">{{ $t('titles.amount') }}</label>
                            <b-form-input
                                id="amount"
                                :placeholder="$t('titles.amount')"
                                v-model="amount"
                                autocomplete="off"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col
                        cols="12"
                        class="px-0 mt-2 d-flex flex-column flex-md-row justify-content-between"
                    >
                        <b-button @click="openResetModal" variant="primary">
                            {{ $t('button.reset') }}
                        </b-button>
                        <b-button
                            @click="setScoreToSubscriber"
                            class="mt-1 mt-md-0 ml-md-2"
                            variant="success"
                            >{{ $t('button.activate') }}
                        </b-button>
                    </b-col>
                </b-card>
            </b-col>
        </b-row>

        <!--    RESET MODAL    -->
        <b-modal
            v-model="resetModal"
            centered
            :title="`${$t('button.reset')} Streak - ${phoneNumber}`"
        >
            <p class="my-4">
                {{ $t('titles.reset_streak') }}
            </p>

            <template #modal-footer="{ cancel }">
                <div class="d-flex align-items-center justify-content-end">
                    <b-button @click="cancel()" variant="danger">{{
                        $t('titles.cancel')
                    }}</b-button>
                    <b-button @click="resetSubscriberStreak" class="ml-1" variant="success"
                        >{{ $t('titles.yes') }}
                    </b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<style scoped lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
